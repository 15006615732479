import React, { useEffect } from "react";
import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from "antd";
import { OrderProps } from "../../../interfaces/interfaces";
import dayjs from "dayjs";

interface Props {
  values: OrderProps;
  setValues: React.Dispatch<React.SetStateAction<OrderProps>>;
  form: FormInstance<any>;
  onClickField: () => void;
}

const format = "MM/DD/YYYY";

const { Option } = Select;

export const OrderForm = ({ values, setValues, form, onClickField }: Props) => {
  useEffect(() => {
    form.setFieldsValue(values);
  }, [form, values]);

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    onClickField();
    setValues((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };
  return (
    <>
      <Form name="order" layout="vertical" initialValues={values} form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="OilCompany" label="Oil Company">
              <Input
                name="OilCompany"
                placeholder="Oil Company"
                value={values.OilCompany}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Rig Number">
              <Input
                name="RigNumber"
                placeholder="Rig Number"
                value={values.RigNumber}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Drilling Company"
              name="DrillingCompany"
              rules={[
                { required: true, message: "Please provide Drilling Company" },
              ]}
            >
              <Input
                name="DrillingCompany"
                placeholder="Drilling Company"
                value={values.DrillingCompany}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Inbound Trucking Company">
              <Input
                name="InboundTruckingCompany"
                placeholder="Inbound Trucking Company"
                value={values.InboundTruckingCompany}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Inbound Trucking Driver">
              <Input
                name="InboundTruckingDriver"
                placeholder="Inbound Trucking Driver"
                value={values.InboundTruckingDriver}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Outbound Trucking Company">
              <Input
                name="OutboundTruckingCompany"
                placeholder="Outbound Trucking Company"
                value={values.OutboundTruckingCompany}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="PO#">
              <Input
                name="PurchaseOrderNo"
                placeholder="PO #"
                value={values.PurchaseOrderNo}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Estimate #">
              <Input
                name="EstimateNo"
                placeholder="Estimate #"
                value={values.EstimateNo}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Outbound Trucking Name">
              <Input
                name="OutboundTruckingName"
                placeholder="Outbound Trucking Name"
                value={values.OutboundTruckingName}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="Status" label="Status">
              <Select
                placeholder="Please choose the Status"
                value={values.Status}
                onChange={(newValue) => {
                  setValues((prev) => {
                    return {
                      ...prev,
                      Status: newValue,
                    };
                  });
                }}
              >
                {["", "Received", "Doped", "Shipped"].map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Date Received"
              rules={[
                {
                  required: true,
                  message: "Please choose the Date Received",
                },
              ]}
            >
              <DatePicker
                name="DateReceived"
                format={format}
                value={values.DateReceived && dayjs(values.DateReceived)}
                style={{ width: "100%" }}
                allowClear={false}
                onChange={(_, dateString) => {
                  setValues((prev) => {
                    return {
                      ...prev,
                      DateReceived: dayjs(dateString),
                    };
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Date Doped"
              rules={[
                {
                  required: true,
                  message: "Please choose the Date Doped",
                },
              ]}
            >
              <DatePicker
                name="DateDoped"
                format={format}
                style={{ width: "100%" }}
                allowClear={false}
                value={values.DateDoped && dayjs(values.DateDoped)}
                onChange={(_, dateString) => {
                  setValues((prev) => {
                    return {
                      ...prev,
                      DateDoped: dayjs(dateString),
                    };
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Location">
              <Input
                name="Location"
                placeholder="Location"
                value={values.Location}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Final Inspection Date"
              rules={[
                {
                  required: true,
                  message: "Please choose the Final Inspection Date",
                },
              ]}
            >
              <DatePicker
                name="FinalInspectionDate"
                format={format}
                allowClear={false}
                value={
                  values.FinalInspectionDate &&
                  dayjs(values.FinalInspectionDate)
                }
                style={{ width: "100%" }}
                onChange={(_, dateString) => {
                  setValues((prev) => {
                    return {
                      ...prev,
                      FinalInspectionDate: dayjs(dateString),
                    };
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Final Inspection Preformed By">
              <Input
                name="FinalInspectionPreformedBy"
                placeholder="Final Inspection Preformed By"
                value={values.FinalInspectionPreformedBy}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Release For Shipment">
              <Input
                name="ReleaseForShipment"
                placeholder="Release For Shipment"
                value={values.ReleaseForShipment}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Date Shipped"
              rules={[
                {
                  required: true,
                  message: "Please choose the Date Shipped",
                },
              ]}
            >
              <DatePicker
                name="DateShipped"
                format={format}
                style={{ width: "100%" }}
                allowClear={false}
                value={values.DateShipped && dayjs(values.DateShipped)}
                onChange={(_, dateString) => {
                  setValues((prev) => {
                    return {
                      ...prev,
                      DateShipped: dayjs(dateString),
                    };
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Notes">
              <Input.TextArea
                name="Notes"
                rows={4}
                placeholder="Notes"
                value={values.Notes}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
